.groupselect {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 0 !important;
}

.navigation-queue {
    color: #8B4DAC;
}

.navigation-visits {
    color: #6D72FE;
}

.navigation-directory {
    color: #A7E4D8;
}

.navigation-reports {
    color: #45AC64;
}

.navigation-pointer {
    cursor: pointer;
}

.card-dashboard {
    text-align: center;
}