.mbsc-page {
  background-image: linear-gradient(36deg, #2962FF, #00AEEF)!important;
}

#card-login {
  background: #efeff4;
}

.card-login {
  text-align: center;
}

.micons {
  background-image: url("logo.png");
  height: 75px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: 25px;
}