#patientselect {
  padding-top: 4px;
}

#newpatient {
  cursor: pointer;
}

.mbsc-ios.mbsc-form-control-wrapper:after, .mbsc-ios.mbsc-form-control-wrapper:before {
  border-width: 0 !important;
}

.logo-non-form {
  margin-left: 32px;
  margin-right: 16px;
  background-image: url("logo.png")!important;
  width: 200px;
  margin-top: 7px;
  background-size: contain;
  background-repeat: no-repeat;
}

.mbsc-ms-hamburger.mbsc-ms-c.mbsc-ms-inline {
  margin-top: 3px;
}

.breadcrumb {
  color: #6a7785;
  cursor: pointer;
}

.header-toolbar {
  background: white;
}

.header-toolbar-right {
  margin-right: 5px;
}

.mbsc-ios.mbsc-input .mbsc-label, .mbsc-ios.mbsc-progress .mbsc-label, .mbsc-ios.mbsc-input input, .mbsc-ios.mbsc-input textarea #header-select {
  color: #6a7785 !important;
  margin-top: 4px;
  font-weight: bold;
}

.header-margin-icon {
  margin-left: 1em;
  padding-top: 8px;
  padding-bottom: 8px;
}

.header-margin-icon .mbsc-avatar {
  height: 2em;
  width: 2em;
}