.node {
    padding: 10px;
    font-size: 12px;
    background: white;
    border: 1px solid #555;
    border-radius: 5px;
    text-align: center;
}

.node-button {
    margin: 0 !important;
    background: white !important;
    padding: .3em .8em !important;
}

.node-button-out {
    margin: 0 !important;
    background: white !important;
    padding: .1em .8em !important;
}

.node-button-edit {
    margin: 0 !important;
    background: white !important;
    padding: .1em .3em .1em .5em !important;
}

.node-button-startend {
    margin: 0 !important;
    padding: .1em .3em .1em 1em !important;
}

.node-group-main {
    margin: 0 !important;
    border-radius: 5px;
}

.node-group {
    margin: 0 !important;
    border-radius: 5px;
}

.node-group-end {
    color: #90979E;
    border: 1px solid #90979E !important;
    border-radius: 5px;
    font-size: 1em;
    background: white !important;
    padding-top: .4em !important;
    padding-bottom: .4em !important;
    padding-left: 2em !important;
}

.react-flow__handle {
    z-index: 3;
}

.node-button-text {
    padding-left: .3em !important;
    text-transform: none;
}