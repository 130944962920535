#flowTitle {
    padding-left: 0;
}

.mbsc-textarea {
    min-height: 82px;
}

.md-master-detail .md-col-border {
    border-right: 1px solid #ccc;
}

.md-master-detail .md-form-margin {
    margin-top: 0;
    margin-bottom: 0;
}

.theme-back-grey {
    background: #efeff4;
}

.secondary-toolbar {
    background: white;
    border-color: #ccc;
    border-top-width: 1px;
    border-top-style: solid;
    border-bottom-width: 1px;
    border-bottom-style: solid;
}

.secondary-toolbar-button-group {
    margin: 0 !important;
    border: 0 !important;
}

.secondary-toolbar-button {
    margin-bottom: 0 !important;
    margin-top: 4px !important;
}

.flow-flex {
    height: 90vh;
}

.options-multiple-button {
    margin-left: 24px !important;
}

.mbsc-ios .mbsc-lv {
    background: #efeff4;
}

.card-node {
    text-align: center;
}

#card-node {
    background: #efeff4;
}