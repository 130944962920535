.subtitle {
    color: whitesmoke;
    padding-top: 36px;
    margin-left: 16px;
}

.user-card {
    margin: 0 !important;
}

.user-card-button {
    margin-left: 0;
}

.queue-icon {
    color: slategrey;
    margin-top: 8px;
}

.queue-icon.mbsc-ic:before {
    font-size: xx-large;
}

.secondary-queue-toolbar {
    background: white;
    border-color: #ccc;
    border-top-width: 1px;
    border-top-style: solid;
    border-bottom-width: 1px;
    border-bottom-style: solid;
}

.assign-note.mbsc-ios .mbsc-note-info {
    margin: 0 !important;
    padding-top: 0.3em;
    padding-bottom: 0.3em
}